import "./Order.css";
import "./csgiaccount.css";
import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
declare global {
    interface Window {
        ascendon: any;
    }
}

interface Dpsk {
    ssid: string;
    wpa_key: string;
    qrCode: string;
};

/* eslint-disable jsx-a11y/anchor-is-valid */

export const Radar = () => {
    const styles = {
        flexAuto: {
            flex: "1 1 auto"
        },
        paddingLeftRight: {
            paddingLeft: "8%",
            paddingRight: "8%"
        },
        borderPaddingMargin: {
            borderRadius: "5px",
            border: "2px solid #ddd",
            paddingLeft: "15px",
            marginRight: "0px"
        },
        margin: {
            marginTop: "10px",
            marginBottom: "10px",
            marginRight: "10px"
        },
        paddingMarginBorder: {
            paddingLeft: "15px",
            paddingTop: "15px",
            paddingBottom: "15px",
            marginRight: "15px",
            border: "1px solid #ddd",
            borderRadius: "5px"
        },
        marginBottom: {
            marginBottom: "5px"
        },
        monospaceFont: {
            fontFamily: "monospace",
            fontSize: "16pt"
        },
        inlineBlock: {
            verticalAlign: "top",
            display: "inline-block",
            textAlign: "center",
            width: "150px"
        },
        imageStyle: {
            width: "120px",
            height: "120px"
        },
        boldText: {
            display: "block",
            fontWeight: "bold",
            padding: "10px"
        }
    };
    const logoutUrl = process.env.REACT_APP_LOGOUT_URL || '';
    const authToken = localStorage.getItem('authorizationToken') as string;
    const claims: any = jwtDecode(authToken);
    const partner = claims.partner;
    const sub = claims.sub;
    const csgSessionId = claims["session-id"]as string;
    const  fedxSessionId= claims["fedx-session-id"]as string;
    const [dpsk, setDpsk] = useState<Dpsk>({ ssid: 'ssid', wpa_key: 'key', qrCode: 'qrCode' });
    const [dpskShow, setDpskShow] = useState(false);
    

    useEffect(() => {
        if (partner.dpskWifi === true) {
            fetch('/api/dpsk', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ authToken
                }
            })
            .then(async response => {
                if (response.status >= 400) {
                    let err = await response.json();
                    console.error('Error:', err.error);
                } else {
                    let data = await response.json();
                    setDpsk(data);
                    setDpskShow(true);
                }
            }).catch(error => {
                console.log(error);
                window.location.href = logoutUrl;
            });
        }

    },[]);
    const onClickGenerateDpsk = () => {
        console.log('onClickGenerateDpsk');
        $('#dpskRenewDialog').modal('show');
        $('#beforeClick').show();
        $('#modalSpinner').hide();
        $('#successRenew').hide();
        $('#errorRenew').hide();
    }

    const copyToClipboard = (text:string) => {
        navigator.clipboard.writeText(text).then(() => {
          // Optionally, implement feedback to the user that the text was copied.
          console.log('Password copied to clipboard!');
        }).catch(err => {
          // Handle any errors
          console.error('Failed to copy text: ', err);
        });
      };

    const onClickDialogYes = () => {
        $('#beforeClick').hide();
        $('#modalSpinner').show();
        fetch('/api/dpsk/reset', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ authToken
            }
        })
            .then(response => response.json())
            .then(data => {
                setDpsk(data);
                $('#modalSpinner').hide();
                $('#successRenew').show();
            })
            .catch(error => {
                console.log(error);
                $('#modalSpinner').hide();
                $('#errorRenew').show();
            });
    }

    window.addEventListener('radar_account_settings_session_expired', (params)=> {
        //handle session expiration
        
            if (logoutUrl) {
                window.location.href = logoutUrl;
            }
      });
    return (
        <div ng-view className="ng-scope">
            <div id="accountcontent">
                <div id="accountcontentmain" className="ascendon accoutnDiv">
                    <div style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                        <div className="row">
                            <div className="col-lg-12">
                                <h1 className="subhead">
                                    <span>Your Account Settings</span>
                                </h1>
                                <div className="headerline"></div>
                            </div>
                        </div>
                    </div>

                    <div style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                        <xoc-account-settings widget-user="XOCStoreFront" external-reference={sub} csgi-session-id={csgSessionId} fedx-session-id={fedxSessionId}></xoc-account-settings>
                    </div>
                    <div className={dpskShow ? 'dpskShow':'dpskHide' }>
                    <div style={styles.borderPaddingMargin}>
                        <div>
                            <h2 style={{ fontFamily: "'XfinityStandardBold', Helvetica, Arial, sans-serif", fontWeight: 700, fontSize: "22px" }}>
                                WiFi Information
                            </h2>
                            <div style={styles.margin}>
                                <div style={styles.paddingMarginBorder}>
                                    <label>Resident Network</label>
                                    <div style={{ ...styles.marginBottom, ...styles.monospaceFont }}>{dpsk.ssid}</div>
                                </div>
                            </div>
                            <div style={styles.margin}>
                                <div style={styles.paddingMarginBorder}>
                                    <label>Password</label>
                                    <div style={{ ...styles.marginBottom, ...styles.monospaceFont }}>{dpsk.wpa_key}
                                    <button style={{marginLeft:"10px", fontSize:"12px", backgroundColor:"#e7e7e7", color:"black"}} className="btn-sm" onClick={() => copyToClipboard(dpsk.wpa_key)} >
                                        Copy
                                    </button>
                                    </div>
                                    <div style={{ marginBottom: "5px"}}><a id="renewDpsk" onClick={onClickGenerateDpsk}>Generate new password</a></div>
                                </div>
                            </div>
                            <div style={{ ...styles.margin, textAlign: "left" }}>
                                <div style={styles.paddingMarginBorder}>
                                    <div style={{
                                        verticalAlign: "top",
                                        display: "inline-block",
                                        textAlign: "center",
                                        width: "150px"
                                    }}>
                                        <img style={styles.imageStyle} src={dpsk.qrCode} alt="QrCode"/>
                                        <span style={styles.boldText}>Scan to connect</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="modal" id="dpskRenewDialog" data-backdrop="static" style={{display: "none"}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" id="beforeClick">
                        <div className="modal-header">
                            <button type="button" className="close" aria-hidden="true" onClick={() => {$('#dpskRenewDialog').modal('hide');}}>&times;</button>
                            <h4 className="modal-title">Are you sure?</h4>
                        </div>
                        <div id="modalBody" className="modal-body"> 
                            Refreshing your password will require re-entering your new password on all your currently connected devices.
                        
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary btn-lg" id="dialogNo" onClick={() => {$('#dpskRenewDialog').modal('hide');}}>No</a>
                            <a type="button" style={{ marginLeft: "5px"}} className="btn btn-primary btn-lg"  id="dialogYes" onClick={onClickDialogYes}>Yes</a>
                        </div>
                    </div>
                    <div className="modal-content" id="modalSpinner" style={{display: "none", height: "300px", textAlign: "center", paddingTop: "120px"}}>
                        <div id="modalBody" className="modal-body"> 
                            <i className='fa fa-spinner fa-pulse' style={{fontSize: "3em"}}></i>
                            Generating new password please wait...
                        </div>
                    </div>
                    <div className="modal-content" id="successRenew" style={{display: "none"}}>
                        <div className="modal-header">
                            <button type="button" className="close" aria-hidden="true" onClick={() => $('#dpskRenewDialog').modal('hide')}>&times;</button>
                            <h4 className="modal-title" style={{textAlign: "center"}}><i className='fa fa-check-circle-o channelavailable'></i> Password Refreshed!</h4>
                        </div>
                            <div  className="modal-body" style={{textAlign: "center"}}>To connect using the new password, forget the network in your device’s 
                            WiFi settings. Wait 1 minute before connecting with the new password.</div>
                        <div id="modalBody" className="modal-body center" style={{textAlign: "center"}}> 
                            <label>New Password</label>
                            <div style={{marginBottom: "5px", fontFamily: "monospace", fontSize: "16pt"}}>{dpsk.wpa_key} 
                                <button style={{marginLeft:"10px", fontSize:"12px", backgroundColor:"#e7e7e7", color:"black"}} className="btn-sm" onClick={() => copyToClipboard(dpsk.wpa_key)} >
                                        Copy
                                    </button>
                                    <br/>
                        </div>									
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary btn-lg" onClick={() => $('#dpskRenewDialog').modal('hide')}>Ok</a>
                        </div>
                    </div>
                    <div className="modal-content" id="errorRenew" style={{display: "none"}}>
                        <div className="modal-header">
                            <button type="button" className="close" aria-hidden="true" onClick={() => $('#dpskRenewDialog').modal('hide')}>&times;</button>
                            <h4 className="modal-title" style={{textAlign: "center"}}><i className='fa fa-times-circle channelunavailable'></i> Error</h4>
                        </div>
                        <div id="modalBody" className="modal-body"> 
                            <div style={{marginBottom: "5px"}}>Your password was unable to be refreshed. Please try again.</div>									
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary btn-lg" onClick={() => $('#dpskRenewDialog').modal('hide')}>Cancel</a>
                            <a type="button" className="btn btn-primary btn-lg" id="dialogErrorYes">Try Again</a>
                        </div>
                    </div>
                </div>
          	</div>
            </div>
        </div>
    );
};