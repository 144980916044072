import { ChannelServices } from "../channelServices/ChannelServices";
import "./Home.css";
import {useState} from 'react';
import {jwtDecode} from 'jwt-decode';
// import { handleWatchNowClick } from '../Utils';
import { useNavigate, Link } from 'react-router-dom';
export const Home = () => {
 const [chromecast, setChromecast] = useState(true);
 const [billions, setBillions] = useState(false);
 const [dvr, setDvr] = useState(true);
 const [app, setApp] = useState(false);
 const [roku, setRoku] = useState(false);
    const handleChrome = () => {
        setChromecast(true);
        setBillions(false);
    };
    const handleBillions = () => {
        setChromecast(false);
        setBillions(true);
    };
    const handleDvr = () => {
        setDvr(true);
        setApp(false);
        setRoku(false);
    }
    const handleApp = () => {
        setDvr(false);
        setApp(true);
        setRoku(false);
    }
    const handleRoku = () => {
        setDvr(false);
        setApp(false);
        setRoku(true);
    }
    const authToken = localStorage.getItem('authorizationToken') as string;
    const claims: any = jwtDecode(authToken);
    const partner = claims.partner;
    let termsAccepted = claims["tc-accepted-date"] as boolean;
    const globalContent = JSON.parse(localStorage.getItem('globalContent') as string);
    let homeContent;
    if(partner.stream && (partner.managedWifi === true || partner.dpskWifi === true)){
        homeContent = globalContent.homewithwifi;
    }else{
        homeContent = globalContent.home;
    }
    console.log(homeContent);
    let  hero = homeContent.hero;
    // const carouselItems = homeContent.carousel.carouselItems;
    // const grid = homeContent.grid;
    // const tabbedAccordion = homeContent['tabbed-accordion'];
    // const xerxesToken = claims.xerxesToken;
    const logoutUrl = process.env.REACT_APP_LOGOUT_URL as string;
    const navigate = useNavigate();
    const handleWatchNowClick = async () => {
        try {
            if (!termsAccepted) {
                navigate('/terms');
                return;
              }
        await fetch('/api/watchNow',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ authToken
            }
        })
        .then(async response => {
            if (!response.ok) {
                window.location.href = logoutUrl;
                throw new Error('Network response was not ok');
            }
            const watchNowResponse = await response.json();
            if (watchNowResponse.expired) {
                // Handle the expiration case
                console.log("The content is expired.");
                window.location.href = logoutUrl;
            } else {
                // Handle the watchNow attribute
                console.log("Watch Now URL:", watchNowResponse.watchNowUrl);
                window.open(watchNowResponse.watchNowUrl, '_blank');
            }
        })
        .catch(error => {
            console.error("Error fetching watchNow data:", error);
            window.location.href = logoutUrl;
        });
          
        } catch (error) {
          console.error("Error fetching watchNow data:", error);
          window.location.href = logoutUrl;
        }
      };
  return (
    <main className="main-content" role='main'>
        <section className="hero-component logged-in">
            <h2>{hero.heading}</h2>
            <p>{hero.copy}</p>
            <div className="link-container">
            {hero.links.map((link: any, index: number) => {
                return (
                    link.isWatchNow ? ( 
                        <a href="#" onClick={link.isWatchNow ? () => handleWatchNowClick() : undefined} key={index}
                        className={`${link.isMobile === true ? 'hide-lg' : 'hide-sm'} ${link.isPrimary === true ? 'btn-primary' : 'btn-secondary-white'} ${link.class}`}>
                            {link.text}
                            <span className="sr-only">{link.sr}</span> 
                        </a>
                    ) : (
                        link.isWifi ? (
                        <Link to={link.url} key={index}
                        className={`${link.isMobile === true ? 'hide-lg' : 'hide-sm'} ${link.isPrimary === true ? 'btn-primary' : 'btn-secondary-white'} ${link.class}`}>
                            {link.text}
                            <span className="sr-only">{link.sr}</span> 
                        </Link>
                        ):(
                        <a href={link.url} key={index} target='_blank'  rel="noreferrer" 
                        className={`${link.isMobile === true ? 'hide-lg' : 'hide-sm'} ${link.isPrimary === true ? 'btn-primary' : 'btn-secondary-white'} ${link.class}`}>
                            {link.text}
                            <span className="sr-only">{link.sr}</span>
                        </a>
                        )
                    )
                );
            })}
            </div>
        </section>
        <section className="tabs carousel">
            <div className="carousel-wrapper inner-column">
                {/* {carouselItems.map((item: any, index: number) => {
                     return ( */}
                    <div className="tabs__tab-content" tabIndex={0} data-panel="0" role="tabpanel" aria-labelledby="tab-0" aria-hidden={!chromecast ? "true" : "false"}>
                        <picture>
                            <source media="(min-width: 768px)" srcSet="/library/images/carousel/TrendChromecast480x448.jpg, /library/images/carousel/TrendChromecast767x715.jpg 2x"/>
                            <source srcSet="/library/images/carousel/TrendChromecast960x896.jpg, /library/images/carousel/TrendChromecast1534x716.jpg 2x"/>
                            <img src="/library/images/carousel/TrendChromecast960x896.jpg" alt="Chromecast" data-cookiecategory="1210"/>
                        </picture>
                            <span className="sr-only">marylandu</span>
                        <section className="tab-item-text">
                            <span>TRENDING</span>
                            <h2>Chromecast</h2>
                            <p>Xfinity Stream now offers casting via Chromecast from your computer or mobile device to your TV screen.</p>
                            <a href="https://www.xfinity.com/support/articles/cast-xfinity-stream-to-chromecast"  target='_blank'  rel="noreferrer" className="btn-primary">Explore</a>
                            <span className="sr-only">Chromecast</span>
                        </section>
                    </div>
                {/* )})} */}
                    <div className="tabs__tab-content" tabIndex={0} data-panel="1" role="tabpanel" aria-labelledby="tab-1" aria-hidden={!billions ? "true" : "false"}>

                        <picture>
                            <source media="(min-width: 768px)" srcSet="/library/images/carousel/Carousel3-Image-480.jpg, /library/images/carousel/Carousel3-Image-960.jpg 2x"/>
                            <source srcSet="/library/images/carousel/Carousel3-Image-767.jpg, /library/images/carousel/Carousel3-Image-1534.jpg 2x"/>
                            <img src="/library/images/carousel/Carousel3-Image-767.jpg" alt="Billions" data-cookiecategory="1210"/>
                        </picture>

                            <span className="sr-only">marylandu</span>

                        <section className="tab-item-text">
                            <span>TRENDING</span>
                            <h2>Billions</h2>
                            <p>To survive on the street never go down without a fight.  Don’t miss season three of Billions.  Upgrade to stream SHOWTIME.</p>
                            <Link to="/upgrade" target="_self" className="btn-primary">Explore</Link>
                            <span className="sr-only">Billions</span>
                        </section>

                    </div>

                        <ul className="tabs__list" role="tablist">
                        <li role="presentation">
                            <button onClick={handleChrome} data-tab="0" role="tab" aria-controls="panel-0" aria-selected={chromecast ? "true" : "false"}>
                                <span className="sr-only">Next promo:</span>
                                <span className="sr-only">Chromecast</span>
                            </button>
                        </li>
                        <li role="presentation">
                            <button onClick={handleBillions} data-tab="1" role="tab" aria-controls="panel-1" aria-selected={billions ? "true" : "false"}>
                                <span className="sr-only">Next promo:</span>
                                <span className="sr-only">Billions</span>
                            </button>
                        </li>
                        </ul>
            </div>
        </section>
        <section className="tabs tabbed-accordion">

            <div className="inner-column">

                <ul className="tabs__list" role="tablist">
                <li role="presentation">
                    <button onClick={handleDvr} data-tab="0" role="tab" aria-controls="panel-0" aria-selected={dvr ? "true" : "false"}>
                    <span className="name">DVR</span>
                    </button>
                </li>
                <li role="presentation">
                    <button onClick={handleApp} data-tab="1" role="tab" aria-controls="panel-1" aria-selected={app ? "true" : "false"}>
                    <span className="name">APP</span>
                    </button>
                </li>
                <li role="presentation">
                    <button onClick={handleRoku} data-tab="2" role="tab" aria-controls="panel-2" aria-selected={roku ? "true" : "false"}>
                    <span className="name">ROKU</span>
                    </button>
                </li>
                </ul>
                <div className="tabs__tab-content dvr">
                <div className="title active" data-title="0">
                    <h2 className="name">DVR</h2>
                    {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
                    <button data-tab="0" aria-controls="panel-0" aria-selected={dvr ? "true" : "false"} onClick={handleDvr}>
                    <span className="show-tab">
                        Show
                        <span className="sr-only">dvr description</span>
                    </span>
                    </button>
                </div>
                <section className="tab-item-text" tabIndex={0} data-panel="0" role="tabpanel" aria-labelledby="tab-0" aria-hidden={!dvr ? "true" : "false"}>
                    <h3>Work around your finals schedule</h3>
                    <picture className="bg-img">
                    <source srcSet="/library/images/tabbed/Tab-Module-DVR-1x.jpg, /library/images/tabbed/Tab-Module-DVR-2x.jpg 2x"/>
                    <img src="/library/images/tabbed/Tab-Module-DVR-1x.jpg" alt="The Xfinity Stream DVR" data-cookiecategory="1210"/>
                    </picture>
                    <div className="content">
                        <p>Manage your DVR from any device to ensure you'll never miss a thing.</p>
                        <div className="features">
                            <div className="feature">
                                <div className="img-wrapper">
                                <img className="icon target" src="/library/images/svg/icon-target.svg" data-cookiecategory="1210" alt=""/>
                                </div>
                            <p>Record and watch two shows at once</p>
                            </div>
                            <div className="feature">
                                <div className="img-wrapper">
                                <img className="icon upload" src="/library/images/svg/icon-upload.svg" data-cookiecategory="1210" alt=""/>
                                </div>
                            <p>20 hours of recording storage</p>
                            </div>
                            <div className="feature">
                                <div className="img-wrapper">
                                <img className="icon play" src="/library/images/svg/icon-play-small.svg" data-cookiecategory="1210" alt=""/>
                                </div>
                            <p>Download and watch even when you're offline</p>
                            </div>
                        </div>
                    </div>

                </section>
                </div>
                <div className="tabs__tab-content app">
                    <div className="title" data-title="1">
                        <h2 className="name">APP</h2>
                        {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
                        <button data-tab="1" aria-controls="panel-1" aria-selected={app ? "true" : "false"} onClick={handleApp}>
                        <span className="show-tab">
                            Show
                            <span className="sr-only">app description</span>
                        </span>
                        </button>
                    </div>
                    <section className="tab-item-text" tabIndex={0} data-panel="1" role="tabpanel" aria-labelledby="tab-1" aria-hidden={!app ? "true" : "false"}>
                        <h3>Lose the dorms, not your shows.</h3>
                        <picture className="bg-img">
                        <source srcSet="/library/images/tabbed/Tab-Module-App-1x.jpg, /library/images/tabbed/Tab-Module-App-2x.jpg 2x"/>
                        <img src="/library/images/tabbed/Tab-Module-App-1x.jpg" alt="The Xfinity Stream App" data-cookiecategory="1210"/>
                        </picture>
                        <div className="content">
                        <p>Download the Xfinity Stream app to watch everything on your mobile devices, as well as record and download to watch even when you're offline.</p>
                        <div className="features">
                            <div className="feature">
                                <a className="img-wrapper" href="https://itunes.apple.com/us/app/xfinity-stream/id731629156?mt=8" target='_blank'  rel="noreferrer">
                                <picture className="icon apple">
                                    <source srcSet="/library/images/tabbed/app-store-badge-1x.png, /library/images/tabbed/app-store-badge-2x.png 2x"/>
                                    <img src="/library/images/tabbed/app-store-badge-1x.png" alt="Download on the App Store" data-cookiecategory="1210"/>
                                </picture>
                                </a>
                            </div>
                            <div className="feature">
                                <a className="img-wrapper" href="https://play.google.com/store/apps/details?id=com.xfinity.cloudtvr&amp;hl=en" target='_blank'  rel="noreferrer">
                                <picture className="icon android">
                                    <source srcSet="/library/images/tabbed/google-play-badge-1x.png, /library/images/tabbed/google-play-badge-2x.png 2x"/>
                                    <img src="/library/images/tabbed/google-play-badge-1x.png" alt="Download with Google Play" data-cookiecategory="1210"/>
                                </picture>
                                </a>
                            </div>
                            <div className="feature">
                                <a className="img-wrapper" href="https://amzn.com/B01DUQOSRQ" target='_blank'  rel="noreferrer">
                                <picture className="icon amazon">
                                    <source srcSet="/library/images/tabbed/amazon-badge-1x.png, /library/images/tabbed/amazon-badge-2x.png 2x"/>
                                    <img src="/library/images/tabbed/amazon-badge-1x.png" alt="Download onto your Amazon Kindle" data-cookiecategory="1210"/>
                                </picture>
                                </a>
                            </div>
                        </div>
                        </div>
                    </section>
                </div>

                <div className="tabs__tab-content roku">
                    <div className="title" data-title="2">
                        <h2 className="name">ROKU</h2>
                        {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
                        <button data-tab="2" aria-controls="panel-2" aria-selected={roku ? "true" : "false"} onClick={handleRoku}>
                        <span className="show-tab">
                            Show
                            <span className="sr-only">roku description</span>
                        </span>
                        </button>
                    </div>
                    <section className="tab-item-text" tabIndex={0} data-panel="2" role="tabpanel" aria-labelledby="tab-2" aria-hidden={!roku ? "true" : "false"}>
                        <h3>Give your shows the screen size they deserve.</h3>
                        <picture className="bg-img">
                        <source srcSet="/library/images/tabbed/Tab-Module-ROKU-1x.jpg, /library/images/tabbed/Tab-Module-ROKU-2x.jpg 2x"/>
                        <img src="/library/images/tabbed/Tab-Module-ROKU-1x.jpg" alt="Xfinity Stream on Roku" data-cookiecategory="1210"/>
                        </picture>
                        <div className="content">
                        <p>Search for Xfinity Stream on your Roku device to watch TV on a bigger screen.</p>
                        </div>
                    </section>
                </div>
            </div>
        </section>
        <section className="grid-component home premium">
                <div className="inner-column">
                    <h2>Premium Channels</h2>
                    <p className="copy">Upgrade today and watch the most talked-about shows and channels</p>
                    <Link className="main-link" to="/upgrade" target="_self">See all Pr  emium Channels</Link>
        <ChannelServices />
                </div>
        </section>
    </main>
  );
}