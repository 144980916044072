import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import "./Channels.css";
import { Link } from 'react-router-dom';

export const Channels = () => {
    const [data, setData] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const authToken = localStorage.getItem('authorizationToken') as string;
    const [networkSortOrder, setNetworkSortOrder] = useState('');
    const [numberSortOrder, setNumberSortOrder] = useState('');
    const [channelSortOrder, setChannelSortOrder] = useState('');
    const claims: any = jwtDecode(authToken);
    let partner = claims.partner;
    let termsAccepted = claims["tc-accepted-date"] as boolean;
    const sortData = (column: any) => {
        if (column === 'network') {
            if (networkSortOrder === 'asc') {
                setData(data.sort((a: any, b: any) => String(a[column]).localeCompare(String(b[column]))));
                setNetworkSortOrder('desc');
                setNumberSortOrder('');
                setChannelSortOrder('');
            } else {
                setData(data.sort((a: any, b: any) => String(b[column]).localeCompare(String(a[column]))));
                setNetworkSortOrder('asc');
                setNumberSortOrder('');
                setChannelSortOrder('');
            }
        } else if (column === 'number') {
            if (numberSortOrder === 'asc') {
                setData(data.sort((a: any, b: any) => a[column] - b[column]));
                setNumberSortOrder('desc');
                setNetworkSortOrder('');
                setChannelSortOrder('');
            } else {
                setData(data.sort((a: any, b: any) => b[column] - a[column]));
                setNumberSortOrder('asc');
                setNetworkSortOrder('');
                setChannelSortOrder('');
            }
        }else if (column === 'channel') {
            if (channelSortOrder === 'asc') {
                setData(data.sort((a: any, b: any) => a['network'] - b['network']));
                setChannelSortOrder('desc');
                setNetworkSortOrder('');
                setNumberSortOrder('');
            } else {
                setData(data.sort((a: any, b: any) => b['network'] - a['network']));
                setChannelSortOrder('asc');
                setNetworkSortOrder('');
                setNumberSortOrder('');
            }
        }
    };

    // const ImageFormatter = ({ row }: { row: any }) => (
    //     <img src={row.imageLocation} alt={row.network} style={{ width: 50, height: 50 }} />
    // );
    // const AvailableFormatter = ({ row }: { row: any }) => {
    //     return (
    //         row.available ? <button>Watch Now</button> : <button>Update</button>
    //     )
    // };
    useEffect(() => {
        // setData([
        //     { id: 1, network: 'New Network A', available: true, imageLocation: 'https://edge.myriad-gn-xcr.xcr.comcast.net/select/logo?width=50&height=50&entityId=8917587223038949117', number: 1 },
        //     { id: 2, network: 'New Network B', available: false, imageLocation: 'https://edge.myriad-gn-xcr.xcr.comcast.net/select/logo?width=50&height=50&entityId=5079751747778553117', number: 2 },
        // ]);
        fetch('/api/channelmap',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ authToken
                }
            })
            .then(response => response.json())
            .then(data => {
                // Add an 'id' property to each item
                const dataWithIds = data.map((item: any, index: number) => ({ ...item, id: index + 1 }));
                setData(dataWithIds);

            })
            .catch((error) => {
                console.error('Error:', error)
             });
    },[authToken]);

    const filteredData = data.filter((item: { network: string }) =>
        item.network.toLowerCase().includes(searchTerm.toLowerCase()) // Assuming 'network' is a property in your data
    );

    // const columns = [
    //     { key: 'id', name: 'ID' },
    //     { key: 'number', name: 'Channel Number' },
    //     { key: 'imageLocation', name: 'Logo', formatter: ImageFormatter },
    //     { key: 'network', name: 'Name' },
    //     { key: 'available', name: 'Available', formatter: AvailableFormatter },
    //     // Add more columns as needed
    // ];
    const logoutUrl = process.env.REACT_APP_LOGOUT_URL as string;
    const navigate = useNavigate();
    const handleWatchNowClick = async () => {
        try {
            if (!termsAccepted) {
                navigate('/terms');
                return;
              }
        await fetch('/api/watchNow',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ authToken
            }
        })
        .then(async response => {
            if (!response.ok) {
                window.location.href = logoutUrl;
                throw new Error('Network response was not ok');
            }
            const watchNowResponse = await response.json();
            if (watchNowResponse.expired) {
                // Handle the expiration case
                console.log("The content is expired.");
                window.location.href = logoutUrl;
            } else {
                // Handle the watchNow attribute
                console.log("Watch Now URL:", watchNowResponse.watchNowUrl);
                window.open(watchNowResponse.watchNowUrl, '_blank');
            }
        })
        .catch(error => {
            console.error("Error fetching watchNow data:", error);
            window.location.href = logoutUrl;
        });
          
        } catch (error) {
          console.error("Error fetching watchNow data:", error);
          window.location.href = logoutUrl;
        }
      };

    return (<>
        <div id="main-content" role="main">
            <section className="hero-component channels">
                <h2>Find your favorites</h2>
                <p>Stream the entertainment you love with Xfinity</p>
                <form action="" aria-atomic="true" aria-live="polite" id="channel-search" name="channel-search">
                    <label className="sr-only" ></label>
                    <span id="autocomplete-description" className="sr-only">Use tab (or down) arrow to access and browse suggestions after input. Confirm your choice with enter key, or esc key to close suggestions box.</span>
                    <input
                        type="text"
                        tabIndex={0}
                        aria-required="true"
                        aria-describedby="autocomplete-description"
                        aria-owns="filtered-channels"
                        id="filter-input"
                        name="filter-input"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                    <button type="submit" aria-label="Submit search"></button>
                </form>
            </section>
            <section className='list channels'>
                <div className="list-wrapper inner-column">
                    <table id="filtered-channels">
                        <thead>
                            <tr>

                            <th className="network" role="columnheader" scope="col">
                                    <span className="sort" data-order={numberSortOrder} data-sort="number" onClick={() => sortData('number')}>
                                        <span className="sr-only">Sort by </span>CHANNEL #
                                    </span>
                                </th>
                                
                                <th className="network" role="columnheader" scope="col" style={{width: "100px"}}>
                                    <span className="sort" data-order={networkSortOrder} data-sort="network" onClick={() => sortData('network')}>
                                        <span className="sr-only">Sort by </span>LOGO
                                    </span>
                                </th>
                                <th className="network" role="columnheader" scope="col">
                                    <span className="sort" data-order={channelSortOrder} data-sort="number" onClick={() => sortData('channel')}>
                                        <span className="sr-only">Sort by </span>CHANNEL NAME
                                    </span>
                                </th>
                               
                                <th className="network" role="columnheader" scope="col" style={{textAlign: 'center'}}>
                                    
                                        <span className="sr-only">Sort by </span>ACCESS
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((item: any) => (
                                <tr style={{ border: '1px solid #ddd' }}>
                                     <td className="number" style={{ textAlign: 'center', border: '1px solid #ddd' }}>{item.number}</td>
                                    <td style={{ textAlign: 'center' , backgroundColor: "#555", border: '1px solid #ddd'}}>
                                        <div className="logo">
                                            <img src={item.imageLocation}  alt={item.network + " logo"} data-cookiecategory="unpublished" style={{ width: '50px' }} />
                                        </div>
                                    </td>
                                    <td className="number" style={{ textAlign: 'left' , paddingLeft: "20px" , border: '1px solid #ddd'}}>{item.network}</td>
                                   
                                    <td className="available watch" style={{border: '1px solid #ddd'}}>
                                        {item.available === 'true' ? (
                                                    <button className="btn btn-secondary" data-network="trueTV HD" onClick={handleWatchNowClick}>Watch Now</button>
                                        ) : partner.upgradeAddons ?(
                                            <Link to="/upgrade" className="btn btn-secondary">Upgrade</Link>
                                        ) : null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </>
    );
};